import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"


import '../styles/grid.css'
const SecondPage = () => (
  <Layout>
    <Seo title="2024 Students" />
    <section className="picture-group">
      <h1 className="students-title">2024 Students</h1>




      <div class="pic-row">
        <div class="pic-column">
          <img src="https://i.imgur.com/6i7rvLK.jpg" alt="stones of light students" />
          <img src="https://i.imgur.com/3wontIS.jpg" alt="stones of light students" />
          <img src="https://i.imgur.com/eUsEliP.jpg" alt="stones of light students" />
          <img src="https://i.imgur.com/qjJKRjX.jpg" alt="stones of light students" />
        </div>

        <div class="pic-column">
          <img src="https://i.imgur.com/EGeynjf.jpg" alt="stones of light students" />
          <img src="https://i.imgur.com/K3wNA7F.jpg" alt="stones of light students" />
          <img src="https://i.imgur.com/WT2GSFX.jpg" alt="stones of light students" />
          <img src="https://i.imgur.com/Pa6SdXN.jpg" alt="stones of light students" />
          <img src="https://i.imgur.com/y93G7wF.jpg" alt="stones of light students" />
        </div>

        <div class="pic-column">
          <img src="https://i.imgur.com/n0DoxVv.jpg" alt="stones of light students" />
          <img src="https://i.imgur.com/YGEao5y.jpg" alt="stones of light students" />
          <img src="https://i.imgur.com/bv3xnKK.jpg" alt="stones of light students" />
          <img src="https://i.imgur.com/Co0ryDn.jpg" alt="stones of light students" />
        </div>

        <div class="pic-column">
          <img src="https://i.imgur.com/Hh3W0y8.jpg" alt="stones of light students" />
          <img src="https://i.imgur.com/Z2EJPOP.jpg" alt="stones of light students" />
          <img src="https://i.imgur.com/XHxOQDf.jpg" alt="stones of light students" />
          <img src="https://i.imgur.com/9TzJePk.jpg" alt="stones of light students" />
        </div>




      </div>



    </section>
  </Layout >
)

export default SecondPage
